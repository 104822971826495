.user__report__container {
  padding: 0 0 1rem 0;
}

.user__report__table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.user__report__header {
  padding: 10px;
  background-color: var(--color-pink);
  color: var(--color-white);
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);
}

.user__report__data {
  text-align: center;
  font-family: var(--fontfamily-default);
  padding: 10px 5px;
  border: none;
  border-bottom: 1px solid grey;
}

.user_report_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}

.user_report_icon {
  font-size: var(--fontsize-medium);
  color: var(--color-blue);
  cursor: pointer;
}
