.coin_history_container {
  padding: 0 0 1rem 0;
}

.coin_history_table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.coin_history_header {
  font-family: var(--fontfamily-sansserif);
  background-color: var(--color-pink);
  font-weight: var(--fontweight-medium);
  color: var(--color-white);
  padding: 0.5rem;
}

.coin_history_data {
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid grey;
  text-align: center;
  padding: 5px;
}

.coin_history_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 20px;
  /* height:10%; */
  margin:'';
  background-color: #eaedff;
}
