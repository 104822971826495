.layout__container {
  display: flex;
  height: 100vh;
  width:100%
  /* flex-direction: column; */
}
.section_one {
  width: 200px;
  height: 100%;
}
.section_two {
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 100px);
   */
  height: 100%;
  /* padding: 0 10px 10px 20px; */
}
.layout__nav__content {
  height: 100vh;
  /* overflow: scroll; */
}
.outlet_container {
  width:100%;
  height: calc(100% - 100px);
  background-color: #f8f9ff;
  padding: 30px 30px 10px 30px;
  overflow: auto;
}
/* .open {
  width: 84%;
  overflow-y: scroll;
}

.close {
  width: 95%;
} */

::-webkit-scrollbar {
  display: none;
}
