.edit_subadmin_bg {
  display: flex;
  justify-content: space-between;
  height:100%;
  padding:40px 20px
}

.edit_subadmin_col {
  width: 45%;
  
}

.edit_subadmin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.edit_subadmin_label {
  font-family: var(--fontfamily-sansserif);
}
