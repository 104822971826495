.subadmin__container {
  padding:0 0 1rem 0;
}

.subadmin__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.subadmin__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-medium);
  padding: 0.5rem 0;
}

.sub__admin__data {
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
  font-family: var(--fontfamily-default);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-regular);
}

.subadmin__edit__icon {
  font-size: var(--fontsize-large);
  color: var(--color-blue);
  cursor: pointer;
}

.subadmin__delete__icon {
  font-size: var(--fontsize-large);
  color: var(--color-red);
  cursor: pointer;
}

.subadmin__view__btn {
  cursor: pointer;
}
