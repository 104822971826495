.host__management__data__styling {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
}

.host__management__header {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
}

.host__management__profile {
  display: flex;
  width: 10rem;
  height: 10rem;
  background-color: aqua;
}

.host__management__table {
  display: flex;
}

.host__management__data {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-family: var(--fontfamily-default);
}

.user__details__row {
  display: flex;
  flex-wrap: wrap;
  height:auto;
}
