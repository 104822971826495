.to_mail_row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selected_mails_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.selected_mails_row::-webkit-scrollbar {
  display: none;
}

.selected_mail_item {
  background-color: #f5f5f5;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  /* font-weight: var(--fontweight-light); */
  padding: 5px;
  color: var(--link-color);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  width: fit-content;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.selected_mail_item span {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #ffff;
  font-size: var(--fontsize-small);
  text-transform: capitalize;
}

.selected_mail_item_remove {
  display: flex;
  margin-left: 10px;
}

.mail_popup_head_row {
  font-family: var(--fontfamily-sansserif);
}

.mail_input_row {
  display: flex;
  align-items: center;
  gap: 10px;
}
