.wallet__container {
  padding:0 0 1rem 0;
}

.wallet__table {
  min-width: 100%;
  width: max-content;
  border-collapse: collapse;
}

.wallet__header {
  background-color: #f20094;
  color: white;
  padding: 10px 0;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
}

.wallet__data {
  text-align: center;
  padding: 5px 5px;
  font-family: var(--fontfamily-sansserif);

  border: none;
  border-bottom: 1px solid grey;
}

.wallet__edit__icon {
  font-size: var(--fontsize-medium);
  color: rgb(0, 145, 255);
  cursor: pointer;
}

.wallet__delete__icon {
  font-size: var(--fontsize-medium);
  color: red;
  cursor: pointer;
}

.wallet__eye__icon {
  font-size: var(--fontsize-medium);
  cursor: pointer;
}

.add__wallet_coin {
  display: flex;
  justify-content: flex-end;
}

.add__wallet__btn {
  margin: auto;
}
