.moment__table__container {
  width: 100%;
  /* max-width: max-content; */
  border-collapse: collapse;
}

.moment__container {
  /* padding: 0 30px 1rem 30px; */
}

.moment__table__head {
  padding: 10px 0;
  background-color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  color: var(--color-white);
}

.moment__table__body {
  text-align: center;
  font-family: var(--fontfamily-default);
  border: none;
  border-bottom: 1px solid grey;
  padding: 5px 0;
}

.moment__table__body__eye_icon {
  cursor: pointer;
  font-size: 18px;
}

.moment__table__edit_icon {
  font-size: 25px;
  color: rgb(0, 126, 204);
  cursor: pointer;
}

.moment__table__delete_icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.moment__table__head {
  background-color: #f20094;
  color: white;
  padding: 10px;
}

.moment_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #eaedff;
}
