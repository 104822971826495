.bucket__container {
  width: 55%;
  max-width:500px;
  background-color: #fff;
  /* padding: 2rem; */
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin: 5rem 0; */
  /* gap: 5rem; */
  filter: drop-shadow(3px 4px 6px #00000029);
  border-radius: 10px;
}
.bucket_main_container{
  width:100%;
  height:100%;
  padding:10px;
}
.edit_coins_profile_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.edit_coins_profile_img_container {
  min-height: 75px;
  min-width: 75px;
  height: 125px;
  width: 125px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  border: 3px solid #6c5dd3;
}
.bucket__coin>p:nth-child(2){
  font-size: 13px;
  color:#6c5dd3;
}
.bucket__coin {
  width: 100%;
  height: 30%;
  display:flex;
  font-size:'14px ';
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color:#8F95B2;
  border-top: 1px solid #8F95B2;
}
.bucket__coin__amount {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  font-size: 18px;
  width: 35%;
  /* padding: 20px 20px; */
}

.bucket__heading {
  font-family: var(--fontfamily-sansserif);
  font-size: 14px;
  font-weight: var(--fontweight-medium);
}

.bucket__para {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}

.bucket__table__container {
  padding: 0.5rem 0;
  width:100%;
  overflow: auto;
}

.bucket__table {
  width: 100%;
  border-collapse: collapse;
}

.bucket__table__header {
  height:80px;
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px 0;
}

.bucket__table__data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  padding: 10px 0;
  border-bottom: 1px solid black;
}

.bucket__icon__para {
  display: flex;
  gap: 10px;
}

.bucket__icon {
  width: 25px;
  height: 25px;
}

.bucket__delete {
  color: var(--color-red);
  font-size: var(--fontsize-medium);
  cursor: pointer;
}
