.dashboard__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  /* background:url("D:/shudh_love_admin_panel/src/base/Assets/Sidebar/dashboardBackground.png" ) no-repeat cover ; */
  /* background: url("D:/shudh_love_admin_panel/src/base/Assets/Sidebar/dashboardBackground.png"); */

  /* padding-bottom: 1vh; */
  overflow: auto;
}
.section_two_dashboard {
  display: flex;
  padding: 10px 20px;
  height: calc(100% - 175px);
}
.dashboard_cards_box {
  position: relative;
  background: url('../../base/Assets/Sidebar/dashboardBackground.png')no-repeat center/cover;
/* background:url(src/base/Assets/Sidebar/dashboardBackground.png) no-repeat center/cover; */
  /* background: url(  C:/Users/USIT/shudh_love_admin_panel/src/base/Assets/Sidebar/dashboardBackground.png) */
    
    /* "D:/shudh_love_admin_panel/src/base/Assets/Sidebar/dashboardBackground.png" */
  width: 100%;
  height: 10%;
  height: 10%;

  min-height: 175px;
  max-height: 300px;
  display: flex;
  align-items: center;

  justify-content: space-evenly;
}

.dashboard_cards_background {
  width: 100%;

  background-color: #ef4689;
  /* border-radius: 20px; */
}

.dashboard__cards_row {
  /* position: absolute;
  top: 10vh;
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow-x: auto;
  overflow-y: visible;
  padding-top: 1rem; */
}

.dashboard__cards_column {
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  transition: all 0.2s ease-in-out;
}

.dashboard__cards_column:hover {
  margin-top: -1rem;
}

.dashboard__notification {
  min-height:500px;
  max-height:800px;
  position: relative;
  width: 25%;
  background-color: white;
  box-shadow: 2px 2px 5.5px rgb(168, 168, 168);
}

.dashboard__graph {
  width: 100%;
  position:relative;
  /* height: 31.4rem; */
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  /* max-height: 550px; */
  /* margin-top: 80px; */
  border-radius: 10px;
  background: #ffff;
  box-shadow: 2px 2px 5.5px #0000;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.dashboard__graphs__loaders {
  display: flex;
  /* gap: 40px; */
  /* overflow-y: auto; */
  max-height:500px;
  height:max-content;
  width: 75%;
  padding: 0px 20px 0px 0px;
}

.dashboard__loader {
  width: 15vw;
  height: 30vh;
  border: 3px solid #ef4689;
  box-shadow: inset 0 0 2px 2px #ef4689;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}

.dashboard__loader_2 {
  width: 15vw;
  height: 30vh;
  border: 3px solid #ef4689;
  box-shadow: inset 0 0 2px 2px #ef4689;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0.5rem;
  align-items: center;
  margin-top: 1rem;
}

.dashboard__loader__heading {
  font-family: var(--fontfamily-sansserif);
}

.dashboard_filter_btn_row {
  display: flex;
  gap: 10px;
  margin-top: 80px;
  margin-bottom: 20px;
  cursor: pointer;
}

.dashboard__host__revenue {
  width: 70%;
  height: 70%;
}

.dashboard__input {
  width: 6rem;
  height: 2rem;
  border-radius: 5px;
}

.dashboard__search__btn {
  position: absolute;
  left: 280px;
  top: 14px;
  z-index: 1000;
}

.dashboard__input__flex {
  display: flex;
  padding: 10px;
  margin-top: -15px;
  gap: 10px;
}
