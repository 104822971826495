.report__user__host__toggle {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  display: flex;
  align-items: center;
}
.report_toggle_btns {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.report__toggle__active__button {
  width: 6rem;
  height: 4rem;
  border: none;
  background-color: var(--color-pink);
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-sansserif);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.report__toggle__inactive__button {
  width: 6rem;
  height: 4rem;
  border: none;
  background-color: var(--color-grey);
  font-size: var(--fontsize-regular);
  font-family: var(--fontfamily-sansserif);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
