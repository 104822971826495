.edit__coins__heading {
  color:#A355D3;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
  font-size: var(--fontsize-small);
}

.edit__coin__btn {
  cursor: pointer;
  min-height: 2rem;
  display:flex;
  font-size:14px;
  width:80px;
  height:30px;
  align-items: center;

}
.edit_coins-container{
  height:100%;
  display:flex;
  width:45%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
