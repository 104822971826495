.mail_detail_box {
  width: 95%;
  height: 80vh;
  overflow: auto;
}

.mail_detail_bg {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background-color: var(--color-white);
  border-radius: 5px 5px 5px 5px;
}

.mail_subject {
  font-size: var(--fontsize-regular);
  font-family: var(--fontfamily-sansserif);
  font-weight: 500;
  text-wrap: wrap;
  width: 70%;
}

.mail_message {
  margin-top: 10px;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  text-wrap: wrap;
}

.mail_date {
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
}

.mail_detail_row {
  display: flex;
  justify-content: space-between;
}

.mail_detail_mails_box {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

.mail_detail_mails_box img {
  width: 70px;
  height: 70px;
}

.user_mails {
  margin-left: 20px;
}
.user_mails table tr td {
  margin: 10px 0;
}

.user_mails table tr td:nth-child(1) {
  display: flex;
  align-items: flex-start;
  width: 100px;
  font-weight: 500;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
}

.user_mails table tr td:nth-child(2) {
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
}

.mail_file_row {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.send_mail_file_item {
  background-color: #f5f5f5;
  padding: 5px 20px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
}

.send_mail_file_item p {
  color: var(--link-color);
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  /* font-weight: 500; */
  margin-right: 10px;
  text-decoration: none !important;
}

.mail_detail_icon {
  font-size: 65px;
}
