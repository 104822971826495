.user__management__suspend__user {
  width: 50%;
  height: 50%;
  max-width:500px;
  max-height:276px;
  min-height:250px;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: hsla(0, 0%, 0%, 0.24) 0px 3px 8px;
}

.suspend__user__heading {
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  font-size:20px;
  margin:0px;
}
.user__details__container,.user__details__row{
  display:flex;
  flex:1;
}
.user__details__table{
  height:100% !important;
}

