.main__card {
  display: block;
  width: 100% !important;
  height: 75%;
  /* border: 1px solid rgb(254, 174, 254); */
  background-color: rgb(254, 254, 254, 0.7);
  /* box-shadow: 3px 3px 5.5px rgba(0, 0, 0, 0.482); */
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

/* .main__card:hover {
  margin: 5rem 0 0 2rem;
  transition: 0.8s ease;
} */
.card_icon_container {
  width: 40px;
  height: 40px;
  background: rgb(254, 254, 254, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.card__heading {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  font-weight: var();
  margin-bottom: 10px;
  color: #64748b;
}

.card__icon__number {
  display: flex;
  gap: 20px;
  padding: 3px 0;
  align-items: flex-start;
}

.card__icon {
  /* font-size: var(--fontsize-large);
   */
  width: 25px;
  height: 25px;
}

.card__number {
  font-size: 20px;
  margin-right: 50px;
}
