.edit__profile__box {
  padding: 3rem;
  background-color: var(--color-lightpink);
  box-shadow: 1px 1px 1px 1px rgb(255, 219, 219);
  border-radius: 15px;
}

.edit__profile__icon {
  width: 18rem;
  cursor: pointer;
}
