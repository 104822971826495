.active_page_btn {
  background-color: white;
  color: #6c5dd3;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  border: none;
  outline: none;
  margin: 0 5px;
  /* box-shadow: 0 1px 2px 1px #ccc; */
  /* font-weight: 500; */
}

.page_btn {
  font-family: var(--fontfamily-sansserif);
  background-color: transparent !important;
  font-size: var(--fontsize-small);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  margin: 0 5px;
  color: white;
  /* box-shadow: 0 1px 2px 1px #ccc; */
  /* font-weight: 500; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination_row {
  display: flex;
  justify-content: center;
  width: 250px;
  background-color: var(--color-pink);
  border-radius: 30px;
  color: white;
  align-items: center;
  height: 35px;
}

.pagination_icon {
  /* background-color: #fff; */
  color: white;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
}

.dot_btns {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: antiquewhite; */
  font-size: 20px;
}

.pagination_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 10%;
}

.per_page_div {
  background-color: var(--color-white);
  border-radius: 5px;
  height: 40px;
  border: none;
  outline: none;
  box-shadow: 0 1px 2px 1px #ccc;
  font-weight: 500;
  display: flex;
  font-weight: var(--fontweight-regular);
  font-family: var(--fontfamily-sansserif);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  margin-left: 5px;
}

.per_page_div select {
  margin-left: 10px;
  /* border: 1px solid black; */
  border-radius: 2px;
  height: 25px;
  font-size: var(--fontsize-small);
  cursor: pointer;
  outline: none;
}

.per_page_div div {
  margin-left: 10px;
  border: 1px solid black;
  border-radius: 2px;
  height: 25px;
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-regular);
  font-family: var(--fontfamily-sansserif);
  cursor: pointer;
  padding: 0 5px;
}

.pagination_select_row {
  display: flex;
  justify-content: flex-end;
  width: 270px;
  position: absolute;
  right: 10px;
}
