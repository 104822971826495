.leader__table__container {
  padding:0 0 1rem 0;
}

.leader__table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.leader__table__header {
  padding: 10px;
  background-color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  color: white;
}

.leader__table__body {
  text-align: center;
  padding: 10px 5px;
  font-family: var(--fontfamily-default);
  border: none;
  border-bottom: 1px solid grey;
}

.leader__table__view {
  cursor: pointer;
}

.leader__edit__icon {
  font-size: var(--fontsize-large);
  color: var(--color-blue);
  cursor: pointer;
}

.leader__delete__icon {
  font-size: var(--fontsize-large);
  color: var(--color-red);
  cursor: pointer;
}
