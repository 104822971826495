.breakcrumbs__container {
  display: flex;
  align-items: center;
  color:white;
  font-size: 18px;
  /* width: auto; */
  height: 50px;
  padding: 0.5rem 1rem;
  /* background: url("C:/Users/USIT/shudh_love_admin_panel/src/base/Assets/BreadCrumbsBackground.png")no-repeat center/cover; */
  /* background: url('D:/shudh_love_admin_panel/src/base/Assets/BreadCrumbsBackground.png') no-repeat center/cover; */
  /* background-color: var(--color-lightgrey);s */
  font-family: var(--fontfamily-sansserif);
  /* text-align: center; */
}
