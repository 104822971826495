.user__sticker__container {
  padding: 0 0 1rem 0;
}

.user__sticker__table {
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
}

.user__sticker__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px;
}

.user__sticker__data {
  text-align: center;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
  padding: 10px 0;
}
