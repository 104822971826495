.feedback__user__host__toggle {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  display: flex;
  align-items: center;
}
.feedback_toggle_btns {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.feedback__toggle__active__button {
  width: 5rem;
  border: none;
  background-color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  font-size: var(--fontsize-regular);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 0;
}

.feedback__toggle__inactive__button {
  width: 5rem;
  border: none;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  background-color: rgb(220, 220, 220);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 0;
}
