.host__moment__container {
  padding:0 0 1rem 0;
}

.host__moment__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.host__moment__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);
  padding: 10px;
}

.host__moment__data {
  text-align: center;
  padding: 5px;
  font-family: var(--fontfamily-default);
  border: none;
  border-bottom: 1px solid grey;
}

.host__moment__eye__icon {
  cursor: pointer;
  font-size: 18px;
}

.host__moment__edit__icon {
  cursor: pointer;
  font-size: 25px;
  color: rgb(0, 157, 255);
}

.host__moment__delete__icon {
  cursor: pointer;
  font-size: 25px;
  color: red;
}
