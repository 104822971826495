.contact_form_container {
  padding:0 0 1rem 0;
  overflow: auto;
}

.contact_form_table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.contact_form_header {
  background-color: var(--color-pink);
  color: white;
  padding: 0.5rem 0;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}

.contact_form_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid black;
  padding: 0.5rem 0;
}

.contact_form_icon {
  font-size: var(--fontsize-medium);
  color: var(--color-red);
  cursor: pointer;
}
