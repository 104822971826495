.admin__unblock {
  cursor: pointer;
}

.admin_block_delete_icon {
  font-size: var(--fontsize-medium);
  color: var(--color-red);
  cursor: pointer;
}

.admin_block_edit_icon {
  font-size: var(--fontsize-medium);
  color: var(--color-blue);
  cursor: pointer;
}
