.add__banner__styling {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  background-color: #eaedff;
  padding: 10px;
}

.banner__search__btn {
  position: absolute;
  left: 200;
  top: 20px;
  z-index: 100;
}
