.snapshots__container {
  padding: 0 0 1rem 0;
  overflow: auto;
}

.snapshots__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.snapshots__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem 0;
}

.snapshots__data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid black;
  padding: 0.5rem 0;
}

.snapshot__icon {
  font-size: var(--fontsize-xsmall);
  color: var(--color-blue);
  cursor: pointer;
}

.snapshots__icon {
  font-size: var(--fontsize-medium);
  color: var(--color-red);
  cursor: pointer;
}

.snapshots__btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}
