.back__to__login {
  text-align: center;
  cursor: pointer;
  font-family: var(--fontfamily-sansserif);
}

.otp__button {
  display: flex;
}

.forgot__otp {
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: var(--fontfamily-sansserif);
  margin-bottom: 5px;
  cursor: pointer;
}
