.assit_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 10px;
}

.assist_chat {
    width: 70%;
    height: 100px;
    min-height: 100px;
    display: flex;
    background-color: #8f95b2;
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px;
    cursor: pointer
}

.assist_chat:hover {
    /* scale:1.2 */
}

.chat_head {
    width: 100%;
    display: flex;
    gap: 50px;
    padding: 10px
}

.chat_description {
    width: 100%;
    padding: 20px
}

.checkbox_input_container {
    position: absolute;
    bottom: 10%;
    left: 3%;
    transform: translate(-3%,10%);
    display:flex;
    font-size:12px;
    width:100%;
    padding:10px;
    padding-left: 20px;
    align-items: center;
    gap:10px;
    font-color:grey;
    justify-content: flex-start;
}
.checkbox_input_container input{
    width:10px !important;
    height:10px !important;
}

.chat_individual {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 50px;
    overflow: break-words;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: 10px;
    background-color: rgb(32, 87, 135);
    border-radius: 5px;
    color: white;
    width: auto;
    padding: 10px;
    padding-bottom: 5px;
    max-width: 300px;
}

.button_container {
    width: 30px;
    height: 30px
}

.button_section input {
    width: 100%;
    height: 100%;
    padding: 4px;
    border-radius: 2px;

}

.button_section {
    width: 100%;
    margin-top: auto;
    max-height: 150px;
    position: relative;
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.timestamp_text {
    font-size: 8px;
    width: 100%;
    text-align: right;
}


.chat_inputs_box {
    width: 100%;
    height: 75%;
    overflow-y: auto;
    margin-top: 10px;
}

.right_section_options {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 10px;
}