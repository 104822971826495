.user__management__icon__container {
  width: 20vw;
  height: auto;
  border-radius: 10px;
  padding-top: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.user__management__icon__row {
  width: 100%;
  /* height: 100%; */
  max-height: 300px;
  display: flex;
  padding: 20px 10px 20px 10px;
  flex-wrap: wrap;
  justify-content: end;
  margin-top:2rem;
  padding: 10px 0px 10px 0px;

}

.user__management__icon__row .icon__container:nth-child(1) {
  border-top-left-radius: 10px;
}
.user__management__icon__row .icon__container:nth-child(7) {
  border-top-right-radius: 10px;
}
.user__management__icon__row .icon__container:nth-last-child(7) {
  border-bottom-left-radius: 10px;
}
.user__management__icon__row .icon__container:nth-last-child(1) {
  border-bottom-right-radius: 10px;
}
.user__management__icon__row .icon__container:nth-last-child(1) :hover{
  /* border-top-right-radius: 10px; */
  right:13px;
  z-index: 99999;
}
 .user__management__icon__row .icon__container:nth-last-child(7):hover {  /* border-bottom-right-radius: 10px; */
  left:13px;
  z-index: 99999;
}

.user__management__icon__row .icon__container:nth-child(1):hover {
  left:13px;
  z-index: 99999;
}
.user__management__icon__row .icon__container:nth-child(7):hover {
  left:13px;
  z-index: 99999;
}
.icon__container:hover  {
  /* left:13px; */
  /* right:13px; */
  transform: scale(1.2);
  border-radius: 10px;
  background-color: #6c5dd3;
  filter: drop-shadow(5px -3px 6px #7373736b);
  transition:width height ease-in-out(0.34s);
  transition: width 2s, height 4s;
  z-index:99
}
.icon__container:hover .icon{
  width: 2.2vw;
  height: auto;
  margin-bottom:20px

}
.icon__container {
  /* width: 14.01%; */
  flex: 0 1 calc(14.28% - 2px);
  /* max-width: 210.45px; */
  margin-left: 2px;
  margin-bottom: 2px;
  height: 120px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 13%; 
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  cursor: pointer;
  /* border-radius: 5px; */
  background-color: #f8f9ff;
  border: 2px solid rgba(108, 93, 211, 0.1);
  /* opacity: 0.1; */
}
.icon__container > * {
  position: absolute; /* Ensure child elements are positioned correctly within the square */
  top: 50%;
  left: 50%;
  width:100%;
  transform: translate(-50%, -50%);
}

.icon {
  width: 1.5vw;
  height: auto;
  margin-bottom:20px
  /* color: black; */
}
@media (min-width: 1200px) {
  .icon__container {
    /* width: 30%; */
    padding-top:20px; /* Adjust the percentage as needed for smaller screens */
  }
}