.bounce_back_container {
  padding: 1rem 0;
  overflow: auto;
}

.bounce_back_table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.bounce_back_header {
  font-family: var(--fontfamily-sansserif);
  background-color: var(--color-pink);
  color: white;
  padding: 0.5rem 0;
}

.bounce_back_data {
  font-family: var(--fontfamily-sansserif);
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
}

.bounce_back_check {
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
}
