.sticker__table {
  min-width: 100%;
  max-width: max-content;
  border-collapse: collapse;
}
.sticker_table_container {
  padding:0 0 1rem 0;
}
.sticker__table__heading {
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 10px;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);

}

.sticker__table__data {
  text-align: center;
  padding: 5px 0;
  font-family: var(--fontfamily-default);

  border: none;
  border-bottom: 1px solid grey;
}

.add__sticker {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #eaedff;
}

.sticker__table__eye__icon {
  cursor: pointer;
  font-size: 20px;
}

.sticker__table__edit__icon {
  font-size: var(--fontsize-medium);
  color: rgb(0, 162, 255);
  cursor: pointer;
}

.sticker__table__delete__icon {
  font-size: var(--fontsize-medium);
  color: red;
  cursor: pointer;
}

.sticker__table thead{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table-container{
  max-height: 400;
  overflow-y: auto;
}

.thead_fixed{
  position: fixed;
  top: 0;
  left: 0;
}