.banner__container {
  padding: 2px 0;
}

.banner__fields__gap {
  padding: 1rem 2rem;
}

.add__banner__button {
  margin: auto;
}

.banner__heading {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  border-bottom: 1px solid black;
  padding: 0.5rem;
}

.banner_para {
  padding-top: 5px 0;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
  display: flex;
  flex-wrap: wrap;
}
