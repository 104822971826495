.notification__card__container {
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
  padding: 0 5px;
  gap: 5px;
  cursor: pointer;
}

.notification__img {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  border: 2px solid white;
}

.notification_message_box {
  width: calc(16vw - 20px);
}

.notification_content {
  /* background-color: var(--color-lightpink); */
  padding: 5px;
  border-radius: 10px;
}

.notification_content_row {
  display: flex;
  /* justify-content: space-between; */
  align-items: end;
  margin-bottom: 5px;
}

.notification_for_btn {
  background-color: white;
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
}

.notification_feedback {
  margin-top: 3px;
  margin-left: 3px;
  padding: 3px 6px;
  width: max-content;
  border-radius: 30px;
  background-color: rgb(0, 119, 255);
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  font-size: 11px;
  text-transform: capitalize;
  color: white;
}

.notification_message {
  font-family: var(--fontfamily-sansserif);
  font-size: 13px;
  color:#64748B;
}

.notification_title {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
  font-weight: var(--fontweight-bold);
  /* width: 50%; */
  color:#1E293B;
}
