.image__popup__styling {
  max-width: 400px;
  max-height: 80vh;
  object-fit: contain;
  text-align: center;
}

.swiper__container {
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Image_heading {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  border-bottom: 1px solid grey;
  margin-bottom: 0.5rem;
}
