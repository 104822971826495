.input__field__container {
  position: relative;
  /* height: 35px; */
  /* width:80%; */
  width:100%;
  gap: 20px;
  align-items: center;
}

.input__field__label {
  font-family: var(--fontfamily-sansserif);
}

.input__styling {
  width: 100%;
  height: 1.5rem;
  border: none;
  outline: none;
  border-bottom: 2px solid #707070 ;
  font-family: var(--fontfamily-sansserif);
  background-color: transparent;
  /* font-size: var(--fontsize-small);
   */
   font-size: var(--fontsize-xsmall);
}

.input__eye__icon {
  position: absolute;
  right: 7px;
  bottom: 5px;
  cursor: pointer;
}

.input__error {
  color: red;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xxsmall);
}

.input_field {
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: center; */
}
