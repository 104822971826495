.host__feedback__container {
  padding:0 0 1rem 0;
}

.host__feedback__table__container {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
}

.host__feedback__comment {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.host__feedback__table__heading {
  padding: 10px;
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
}

.host__feedback__table__data {
  text-align: center;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
  padding: 10px 5px;
}

.host__feedback__view__btn {
  cursor: pointer;
}
.host__feedback__eye__icon {
  font-size: 20px;
  cursor: pointer;
}

.table_parent_box {
  width: 100%;
  overflow-x: auto;
}

.host_feedback_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}

.host_feedback_btn_click {
  text-align: center;
  padding: 3px;
  background-color: var(--color-lightpink);
  outline: none;
  border: 1px solid var(--color-pink);
  border-radius: 2px;
  cursor: pointer;
}
