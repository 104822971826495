.notification_content_box {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.notification__heading {
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontsize-regular);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
  color: #111827;
  text-align: center;
  width: 100%;
  height: 40px;
  /* background-color: #ef4689; */
  z-index: 11;
}

.notification__search_icon_bar {
  width: 13rem;
  background-color: white;
  border-radius: 10rem;
  height: 2rem;
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
  margin-left: 20px;
}

.notification__search {
  width: 80%;
  position: relative;
  border-radius: 10rem;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: var(--fontsize-small);
}
