.add__gift__container {
  padding: 5px 0;
}

.add__gift__form__btn {
  margin: auto;
  cursor: pointer;
}

.add__gift__heading {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
  /* border-bottom: 1px solid black; */
  color:#A355D3;

  padding: 0.5rem 0;
}

.add__gift__form {
  padding: 1rem 2rem;
}

.add_gift_para {
  font-family: var(--fontfamily-sansserif);
  font-size: 13px;
  /* background-color: red; */
  margin-top: 15px;
}

.add_gift_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
