.user__details__row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_suspend_button_container {
  width: 100%;
  display: flex;
  gap: 8px;
}

.edit_suspend_edit {
  color: #6C5DD3;
  background-color: #E7E7FF;
  width: 50px;
  height: 25px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.edit_suspend_suspend{
  color: #FF0000;
  background-color: #FFE0DB;
  width: 75px;
  height: 25px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  /* padding:0; */
}

.user__details__table {
  display: flex;
  width: 100%;
  color: #8f95b2;
  gap: 4px;
  height: auto;
  flex-direction: column;
  padding: 10px 10px 10px 30px;
  justify-content: space-evenly;
}

.user__table__data div {
  /* text-overflow: ellipsis; */

  word-break: break-all;
}

.user__table__data {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  height: auto;
  /* gap: 10px; */
  /* padding: 10px 0; */
}

.user__table__data th {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
}

.user__table__data td {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
}

.user__management__profile {
  width: 100%;
  /* height: 40%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.image_profile_container {
  width: 150px;
  height: 150px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #6c5dd3;
  border-radius: 50%;
}

.user__management__profile__name>p:nth-child(2) {
  margin-right: auto;
  gap: 20px
}

.user__management__profile__name div>p:nth-child(2) {
  margin-inline: auto;
  color: #6C5DD3;
  font-size:var(--fontSize-x-small);
  font-weight:bold
}

.user__management__profile__name {
  padding-block: 10px;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-top: 1px solid #8f95b2;
  border-bottom: 1px solid #8f95b2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.userdetails_main_container {
  /* padding-inline: 15px; */
  width: 35%;

  max-width: 350px;
  /* height: 100%; */
  height: auto;
  max-height: 586px;
  min-height:500px;
  background-color: #fdfeff;
  filter: drop-shadow(3px 4px 6px #00000029);
  border-radius: 6px;
  display: flex;
  min-height: 570px;
  flex-direction: column;
  /* overflow-y: hidden; */
  /* justify-content: space-between; */
}

.user__management__profile_details {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 30px 0px 20px 0px;
  /* gap: 20px; */
}

.user_management_icon {
  align-items: flex-end;
  color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.user__details__container {
  width: 100%;
  /* height: 40%; */
  /* overflow: auto; */
  height: auto;
  /* padding-block: 10px; */
}