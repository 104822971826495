.suspicious__data__container {
  padding:0 0 1rem 0;
}

.suspicious__data__table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.suspicious__data__header {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 0.5rem 0;
}

.suspicious__data__data {
  font-family: var(--fontfamily-default);
  text-align: center;
  font-size: var(--fontsize-small);
  border-bottom: 1px solid black;
  padding: 0.3rem 0;
}

.suspicious__edit__icon {
  font-size: var(--fontsize-medium);
  color: var(--color-blue);
  cursor: pointer;
}

.suspicious__delete__icon {
  font-size: var(--fontsize-medium);
  color: var(--color-red);
  cursor: pointer;
}

.suspicious_data_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}
