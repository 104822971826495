.secondary__btn__container {
  width: auto;
  height: 2rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-pink);
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.active_btn__text {
  font-family: var(--fontfamily-default);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-medium);
  color: var(--color-white);
}

.secondary__btn__text {
  font-family: var(--fontfamily-default);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-medium);
  color: var(--color-pink);
}
