.sidebar__content_container {
}

.sidebar_div {
  width: 200px;
  background: white;
  height: 100%;
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  filter: drop-shadow(9px 3px 18px #00000029);
}
/* .navbar_subMenu :nth-child(1):before{
  content: '\A';
  width:5px;
  height:5px;
  border:2px solid;
  color:#6c5dd3;
  display: inline;
} */
.navbar_subMenu p::before{
  content:'\A';
  width:7px;
  height:7px;
  margin-right:10px;
  border-radius: 50%;
  border:2px solid #6C5DD3;
  background-color: white;
  display:inline-block;
}


.sidebar_content {
  text-align: left;
  width: 100%;
  height: 85%;
  padding-top:5px ;
  color: #8f95b2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
  margin-top:0px;
}
.sidebar_item_container {
  padding: 0px 4px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 45px;
  height:45px;
}
.active_sidebar_item {
  width: 100%;
  display: flex;
  background-color: #6c5dd3;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: left;
  height: 100%;
}
.sidebar_item {
  width: 100%;
  font-size: 16px;

  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sidebar_label_container {
  width: 100%;
  display: flex;
  text-align: left;
  padding: 0px 10px;
  align-items: center;
  

  height: 100%;
}
.sidebar_item_container_active_submenu {
 
  height:max-content
}


.sidebar__label_active_sub_data {
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: var(--fontweight-regular);
  border: none;
  outline: none;
  margin: 10px 0px;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.navbar_subMenu {
  display: flex;
  margin:13px 0px 15px 30px;
  flex-direction: column;
  justify-content: space-evenly;
  max-height:175px;
}

.navbar_subMenu p {
  font-size: 12px;
  color: #8f95b2;
  padding: 5px 0;
  cursor:pointer
}
.sidebar_item_container_active_submenu .sidebar_item_container .sidebar_item{
  /* position: relative; */

}
/* .sidebar_item_container_active_submenu .sidebar_item_container .sidebar_item::after{
  content: '\A';
    width: 2px;
    height: 17px;
    background-color: #6c5dd3;
    display: inline;
    position: absolute;
    bottom: -21px;
    left: 20px;

} */

.sidebar_item_container_active_submenu {
  width:100%;

  transition: max-height 0.3s ease;
}

.navbar_subMenu {
  transition: max-height 0.3s ease;
}