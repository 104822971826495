.opened__by__container {
  padding: 1rem 0;
  overflow: auto;
}

.opened__by__table {
  width: 100%;
  border-collapse: collapse;
}

.opened__by__header {
  background-color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  color: var(--color-white);
  padding: 0.3rem 0;
}

.opened__by__data {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
}
