.user__notification__container {
  height:80%;
  overflow-y: auto;
  padding: 3rem 0;
}

.user__notification__table {
  width: 100%;
  border-collapse: collapse;
}

.user__notification__header {
  padding: 10px;
  background-color: var(--color-pink);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  color: var(--color-white);
}

.user__notification__data {
  text-align: center;
  padding: 0.5rem;
  border: none;
  font-family: var(--fontfamily-default);
  border-bottom: 1px solid grey;
}

.no__data__found {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  font-weight: var(--fontweight-medium);
  color: var(--color-grey);
  margin-top: -20px;
}
