.deleted_account_container {
  padding:0 0 1rem 0;
}

.deleted_account_table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.deleted_account_header {
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 0.5rem;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
}

.deleted_account_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid grey;
  padding: 10px;
}
