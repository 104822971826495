.feedback__table__container {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
}

.feedback__container {
  padding: 0 0 1rem 0;
}

.feedback__table__comment {
  width:100% !important;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: center;
}

.feedback__table__heading {
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 10px;
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);
}

.feedback__table__data {
  padding: 10px 5px;
  text-align: center;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
}

.feedback__table__eye__icon {
  font-size: 20px;
  cursor: pointer;
}

.feedback__table__reply {
  cursor: pointer;
}

.feedback_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}

.host_feedback_btn_disable {
  text-align: center;
  padding: 3px;
  background-color: var(--color-lightgrey);
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}
