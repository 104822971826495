.popup__notifications {
  width: 25vw;
  height: 80vh;
  overflow: scroll;
  z-index: 10000;
}

.notification_box {
  z-index: 10000;
}

.popup__heading__content {
  display: flex;
  border-bottom: 1px solid black;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.popup__clear__all {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-regular);
  text-decoration: underline;
  color: var(--color-blue);
  cursor: pointer;
}

.pop__content {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-large);
  padding: 5px 0;
}

::-webkit-scrollbar {
  display: none;
}

.appType__styling {
  width: auto;
  height: auto;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  background-color: var(--color-lightpink);
}

.popup__date_time {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
  font-weight: var(--fontweight-regular);
}

.popup__row {
  border-bottom: 1px solid black;
  padding: 0.5rem 0;
  cursor: pointer;
}

.popup__appType {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
  font-weight: var(--fontweight-regular);
}

.popup__heading {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  font-size: var(--fontsize-regular);
  text-align: left;
  padding: 0 0 0 10px;
}

.popup__notify__content {
  border-bottom: 1px solid black;
  text-align: left;
  padding: 0.4rem;
}

.popup__notification__body {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-small);
  padding: 0.4rem 0 0 10px;
  text-align: center;
}

.popup__cross__icon {
  font-family: var(--fontfamily-sansserif);
}

/* .popup__notification__nodata {
  width: auto;
  font-family: var(--fontfamily-sansserif);
} */
