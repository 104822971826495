.payment__history__container {
  padding:0 0  1rem 0;
}

.payment__history__table {
  width: 100%;
  border-collapse: collapse;
}

.payment__history__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 10px;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  font-size: var(--fontsize-regular);
}

.payment__history__data {
  text-align: center;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  font-family: var(--fontfamily-default);
}

.payment__no__data {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  font-weight: var(--fontweight-medium);
  color: var(--color-grey);
  margin-top: -20px;
}
