.apk_container {
  padding: 0 0 1rem 0;
}

.apk_table {
  border-collapse: collapse;
  max-width: max-content;
  min-width: 100%;
  background-color: #EAEDFF;
}

.apk_header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
}

.apk_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
  border-bottom: 1px solid grey;
}
