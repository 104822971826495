.flower__container {
  padding: 1rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 50px;
}

.flower__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
  min-height: 40%;
}

.flower__header {
  font-family: var(--fontfamily-sansserif);
  background-color: var(--color-pink);
  font-weight: var(--fontweight-medium);
  color: var(--color-white);
  padding: 0.5rem 0;
}

.flower__data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border: none;
  border-bottom: 1px solid black;
  padding: 0.5rem 0;
}

.flower__eye__icon {
  font-size: var(--fontsize-medium);
  cursor: pointer;
}

.flower__edit__icon {
  font-size: var(--fontsize-large);
  color: var(--color-blue);
  cursor: pointer;
}

.flower__delete__icon {
  font-size: var(--fontsize-large);
  color: var(--color-red);
  cursor: pointer;
}