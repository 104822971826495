.user__management__container {
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  /* gap: 80px; */
  /* justify-content: center; */
}
.user__management__profile_name_container{
  gap:20px;
}
.user__management__profile_name_container>:nth-child(2){
  margin-right: auto;
}
.user__management__profile_name_container {
  width: 100%;
  /* height: 20%; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #8f95b2;
  font-size: 18px;
  padding: 3px 10px;

}
.user__management__profile_pic {
  width: 100%;
  height: 100%;
  /* margin-top: 0.5rem; */
  border-radius: 100%;
  background-color: aliceblue;
}

.user__management__icon_suspend_warn_user {
  display: flex;
  /* gap: 8rem; */
  padding: 20px 20px 10px 0px;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
input[type="date"] {
  background-color: white;
  border: none;
  padding: 3px;
  border-radius: 3px;
  min-height:30px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.user__management__action {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  padding-left: 40px;
  /* justify-content: space-between; */
  gap:35px;
  /* padding-left: ; */
}
