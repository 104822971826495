.edit__profile__container {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 5rem 0;
  padding: 1rem;
  background-color: #eaedff;
}

.edit__profile__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: var(--color-white);
  border: 1px solid #6c5dd3;
  margin: 1rem 0;
  height: 3rem;
  cursor: pointer;
}

.edit__profile__content {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  font-weight: var(--fontweight-medium);
}
