.web__modal__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.183);
  z-index: 10000000;
  overflow-y: hidden;
}

.web_modal_data {
  background-color: var(--color-white);
  border-radius: 10px;
}

.web__modal__heading {
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  text-align: center;
  padding: 0.2rem 0.5rem;
}

.web__modal__content {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 10px;
  font-weight: var(--fontweight-regular);
  font-family: var(--fontfamily-sansserif);
  max-width: 25rem;
  max-height: 20rem;
  overflow-wrap: break-word;
  overflow: scroll;
}

.web_modal_icon {
  cursor: pointer;
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-right: 10px;
}
