.send_mails_header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.send_mails_header button {
  margin: 0.5rem;
}

.send_mail_box {
  bottom:20px;
  position: absolute;
  right: 20px;
 height:80%;
 z-index:99;
}

.send_mails_container {
  padding-top: 1rem;
  overflow: auto;
}

.send_mails_table {
  border-collapse: collapse;
  max-width: max-content;
  min-width: 100%;
}

.send_mails_heading {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem 0;
}

.send_mails_data {
  font-family: var(--fontfamily-sansserif);
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
}
