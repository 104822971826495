.mail_item_bg {
  background-color: #EAEDFF;
  padding: 0.5rem 15px;
  border-bottom: 1px solid black;
  /* margin-top: 0.5rem; */
  cursor: pointer;
  overflow: hidden;
}

.mail_item_bg:hover {
  box-shadow: inset 0 0 2px 1px #9a99984e;
  background-color: #EAEDFF;
}

.mails_row {
  display: flex;
  color:#8F95B2;
  justify-content: space-between;
}

.mails_row div {
  display: flex;
}

.mail_item_to {
  width: 190px;
  padding-right: 50px;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  font-weight: 500;
}

.mail_item_title {
  font-family: var(--fontfamily-sansserif);
  padding-right: 10px;
  font-weight: 500;
  font-size: var(--fontsize-small);
}

.mail_item_message {
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
}

.mail_files {
  margin-top: 1px;
  display: flex;
}

.mail_file {
  display: flex;
  align-items: center;
  color:#8F95B2;
  text-wrap: nowrap;
  border: 1px solid rgb(203, 203, 203);
  font-family: var(--fontfamily-sansserif);
  max-width: 150px;
  height: 30px;
  overflow: hidden;
  border-radius: 40px;
  padding: 0 5px;
  margin-right: 10px;
}

.mail_file div {
  font-size: 20px;
  display: flex;
  color: var(--color-red);
  padding-right: 10px;
}

.mail_file p {
  width: 100px;
  overflow: hidden;
  font-size: 0.8rem;
  margin-right: 5px;
}

.mail_time {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
}
