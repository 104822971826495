.notification__table__container {
  padding:0 0  2rem 0;
}

.notification__table__table {
  width: 100%;
  border-collapse: collapse;
}

.notification__table__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px;
}

.notification__open {
  cursor: pointer;
}

.notification__table__data {
  text-align: center;
  padding: 0.5rem;
  font-family: var(--fontfamily-default);
  border: none;
  border-bottom: 1px solid grey;
}

.notification__table__edit__icon {
  font-size: 25px;
  color: rgb(0, 128, 255);
  cursor: pointer;
}

.notification__table__delete__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.notification__eye__icon {
  font-size: var(--fontsize-regular);
  cursor: pointer;
}
