.warned__user__container {
  padding: 0 0 1rem 0;
}

.warned__user__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.warned__user__header {
  background-color: #f20094;
  color: white;
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);

  padding: 10px;
}

.warned__user__data {
  text-align: center;
  padding: 5px 0;
  border: none;
  font-family: var(--fontfamily-default);
  border-bottom: 1px solid grey;
}

.warned__user__edit__icon {
  font-size: 25px;
  color: rgb(0, 140, 255);
  cursor: pointer;
}

.warned__user__delete__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.warned_user_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}
