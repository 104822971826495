.rejected__host__container {
  padding: 0 0 1rem 0;
  /* overflow: auto; */
}

.rejected__host__table {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
}

.rejected__host__data {
  padding: 10px 5px;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
  text-align: center;
}

.rejected__host__view_btn {
  cursor: pointer;
}

.rejected__host__eye__icon {
  cursor: pointer;
  font-size: 20px;
}

.rejected__host__edit__icon {
  font-size: 25px;
  color: rgb(0, 119, 255);
  cursor: pointer;
}

.rejected__host__delete__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.rejected__host__header {
  background-color: #f20094;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-medium);
  padding: 10px;
  color: white;
}

.rejected_host_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}
