.sticker__form__container {
  padding: 2px 0;
}

.sticker__form__header {
  text-align: center;
  color:#A355D3;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  border-bottom: 1px solid black;
  padding: 0.5rem 0;
}

.sticker__submit__btn {
  margin: auto;
}

.sticker__form {
  padding: 1rem 2rem;
}
