.navbar__container {
  height: 100px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  background-color: white;
  z-index: 50;
  filter: drop-shadow(5px 14px 6px #00000029);
  top: 0;
}
.profile_details {
  color: #111827;
}
.profile_details > p {
  font-size: 39px;
}
.navbar__country {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  /* background-color: #ef4689; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar__profile_details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_right_content {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__details {
  color: #f20094;
  font-family: var(--fontfamily-default);
}

.admin__image {
  font-size: 25px;
  text-align: center;
  color: #111827;
}

.notification_parent {
  position: relative;
}

.notification_navbar_count {
  position: absolute;
  top: -10px;
  right: -5px;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  height: 28px;
  width: 28px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: white;
  border: 3px solid white;
  background-color: #ef4689;
}
