.delete_moments_container {
  padding:0 0 1rem 0;
}

.delete_moments_table {
  min-width: max-content;
  width: 100%;
  border-collapse: collapse;
}

.delete_moments_header {
  background-color: var(--color-pink);
  color: white;
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
}

.delete_moments_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid grey;
  padding: 0.5rem;
}
