.user__call__history__container {
  overflow: auto;
}

.user__call__history__table {
  width: 100%;
  border-collapse: collapse;
}

.user__call__history__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 10px 0;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  font-weight: var(--fontweight-medium);
}

.user__call__history__data {
  text-align: center;
  padding: 10px 5px;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
}

.user__random__call {
  background-color: var(--color-pink);
  color: var(--color-white);
  border-radius: 80px;
  padding: 0.2rem 0;
}
