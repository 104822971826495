.select_checkbox_parent_wrapper {
  overflow: hidden !important;
  border: 2px solid #f20094 !important;
  border-radius: 5px;
}
.select_checkbox_wrapper {
  outline: none !important;
  border: none !important;
  display: flex;
  /* border: 1px solid red !important; */

  justify-content: center;
  height: 42px;
  overflow: hidden !important;
  width: 120%;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px;
  padding: 0 10px;
  outline: transparent !important;
  border: transparent !important;
}

.label_style {
  font-family: var(--fontfamily-default);
}
