.user__block__list__container {
  padding:0 0 3rem 0;
}

.user__block__list__table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.user__block__list__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px;
}

.user__block__list__data {
  text-align: center;
  padding: 10px 0;
  border: none;
  font-family: var(--fontfamily-default);
  border-bottom: 1px solid grey;
}

.block_no__data__found__icon {
  text-align: center;
}

.block__para {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  font-weight: var(--fontweight-medium);
  color: var(--color-grey);
  margin-top: -20px;
}
