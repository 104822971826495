.banner__list__table__container {
  min-width: 100%;
  width: max-content;
  border-collapse: collapse;
}

.banner__list__container {
  padding: 0 0 1rem 0;
}

.banner__list__table__head {
  padding: 10px 0;
  background-color: #eaedff;
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);

  color: white;
}

.banner__list__eye__icon {
  font-size: 20px;
  cursor: pointer;
}

.banner__list__edit__action {
  font-size: var(--fontsize-medium);
  color: rgb(0, 99, 213);
  cursor: pointer;
}

.banner__list__delete__action {
  font-size: var(--fontsize-medium);
  color: red;
  cursor: pointer;
}

.banner__list__data {
  border: none;
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  padding: 5px;
  border-bottom: 1px solid grey;
}

.banner__list__body {
  padding: 10px;
}

.user_request_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}
