.user__following__container {
  padding: 3rem 0;
}

.user__following__table {
  width: 100%;
  border-collapse: collapse;
}

.user__following__header {
  background-color: #f20094;
  color: white;
  padding: 10px;
}

.user__following__data {
  text-align: center;
}
