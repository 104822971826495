.profile_container {
  position: relative;
}

.pop_container {
  position: absolute;
  z-index: 50;
  width: auto;
  font-size: var(--fontsize-regular);
  font-weight: 500;
  color: var(--color-black);
  border-radius: 5px;
  right: 20px;
  background-color: var(--color-white);
  text-align: center;
  border: none;
  outline: none;
  z-index: 2000;
  box-shadow: 1px 1px 1px 1px rgb(255, 232, 236);
}

.popup_text p {
  font-family: var(--fontfamily-default);
  border-bottom: 1px solid black;
  cursor: pointer;
  padding: 0.5rem 2rem;
}
