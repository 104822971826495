.user__request__table__container {
  /* padding: 0 30px 1rem 30px; */
}

.user__request__table {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
}

.user__request__headers {
  border: none;
  color: var(--color-white);
  padding: 10px;
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  background-color: var(--color-pink);
}

.user__request__data {
  text-align: center;
  padding: 5px;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  border-bottom: 1px solid grey;
}

.user__request__eye__icon {
  cursor: pointer;
  font-size: 20px;
}

.user__request__eye__icon {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.accepted__user__edit__icon {
  width: 25px;
  height: auto;
  color: rgb(0, 178, 0);
  cursor: pointer;
}

.accepted__user__delete__icon {
  width: 25px;
  height: auto;
  color: red;
  cursor: pointer;
}

.user__management__view__btn {
  cursor: pointer;
}

.user__request__btn__container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.user_request_date_picker {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: none;
}

.user_request_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;

}

/* .user_request_date {
  position: absolute;
  top: 0;
  width: 140px;
  height: 40px;
  opacity: 0;
  background-color: red;
  cursor: pointer;
} */

label {
  font-family: var(--fontfamily-sansserif);
}

/* .date_input_parent {
  position: relative;
  width: 150px;
  height: 40px;
  background-color: var(--color-lightpink);
  overflow: hidden;
  padding: 0 5px;
} */

.date_input_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  height: 40px;
}
