.login__otp_field {
  display: flex;
  gap: 20px;
}

.login__otp_field input {
  height: 50px;
  width: 50px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.login_para {
  font-family: var(--fontfamily-sansserif);
}

.login_heading {
  font-family: var(--fontfamily-sansserif);
}
