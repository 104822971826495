.mail_popup_bg {
  box-shadow: 0px 1px 2px 1px #807f7e4e;
  background-color: var(--color-white);
  border-radius: 10px 10px 0 0;
  width: 500px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: height 10s ease;
}

.mail_popup_head_row {
  display: flex;
  justify-content: space-between;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fotnweight-regular);

  align-items: center;
  height: 20px;
  background-color: #eaedff;
  padding: 15px;
}

.mail_popup_head_row p {
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  font-weight: 500;
  color: #000;
}

.mail_input_row {
  display: flex;
  align-items: center;
  margin: 0 10px;
  border-bottom: 1px solid rgba(155, 155, 155, 0.5);
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  padding: 10px 0;
}

.mail_input_row .input_parent_div {
  margin: 0 !important;
}

.mail_input_row .input_parent_div input {
  margin-left: 10px;
}

.mail_message_box {
  padding: 10px;
  margin-bottom: 50px;
}

.auto_height {
  /* min-height: 50px; */
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}

.mail_bottom_box {
  position: absolute;
  bottom: 0;
  /* width: 100%; */
  display: flex;
  align-items: center;
  padding: 0 10px 10px 10px;
  height: 50px;
  background-color: #fff;
}

.file_link_icon_style {
  font-size: 1.2rem;
  transform: rotate(-45deg);
}

.image-upload > input {
  display: none;
}

.image-upload .file_link_icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_upload_mail_bg {
  background-color: rgba(192, 192, 192, 0.593);
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 2px;
}

.mail_selected_file {
  background-color: #f5f5f5;
  font-size: var(--fontsize-xsmall);
  padding: 5px 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.mail_selected_file p {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-xsmall);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mail_checkbox_row {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.mail_checkbox_row label {
  text-transform: capitalize;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  padding: 0 10px 0 5px;
}

.mail__input {
  padding-left: 15px;
}
