.top__talent__container {
  padding: 0 1rem;
}

.web_table_container {
  width: 100% !important;
  height: 70% !important;
  overflow: auto !important;
  /* padding: 0 30px 1rem 30px; */
}

.top__talent__table {
  width: 100%;
  min-width: 100%;
  /* height: 70% ; */
  max-height:70%;
  overflow-y: auto !important;
  border-collapse: collapse;
}
table{
  width:100%;
  border-spacing: 0px;
}
table th td{
  border:none;
}

.top__talent__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);

  /* padding: 10px; */
}

.top__talent__data {
  text-align: center;
  font-family: var(--fontfamily-default);
  padding: 10px;
  border: none;
  border-bottom: 1px solid grey;
}
