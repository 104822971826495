.accepted__host__table {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
}

.accepted__host__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-small);
  padding: 10px;
}

.accepted__host__view {
  cursor: pointer;
}

.eye__btn {
  cursor: pointer;
  font-size: var(--fontsize-regular);
}
.accepted__host__data {
  padding: 5px 10px;
  border: none;
  /* border-bottom: 1px solid grey; */
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  text-align: center;
}

.host__leader__edit {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.accepted__host__edit__icon {
  font-size: 25px;
  color: rgb(0, 119, 255);
  cursor: pointer;
}

.accepted__host__delete__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.accepted__host__edit_icon {
  cursor: pointer;
}

.accepted__host__search__btn {
  position: absolute;
  left: 200;
  top: 20px;
  z-index: 100;
}

.accepted_host_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}

.accepted_host_container {
  padding: 0 0 1rem 0;
}
