/* .search__input__container {
  position: absolute;
  top: 10vh;
  right: 20px;
  color: var(--color-pink);
  background-color: aqua;
}

.search__input {
  padding-left: 40px;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-default);
  background-color: var(--color-lightpink);
}

.search__icon {
  top: 10px;
  left: 20px;
  font-size: 20px;
  text-align: center;
} */

.search__input__bg {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.5rem;
}

.search__input__container {
  position: absolute;
  top: 22px;
  right: 175px;
  z-index: 100;
  background-color: #FAFAFA;
  height: 50px;
  width: 300px;
  display: block;
  display: flex;
  border-radius: 12px;
}

.search__input {
  background-color: transparent;
  width: 260px;
  height: 40px;
  color: black;
  border: none;
  outline: none;
  padding: 0 3px;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
}

.search__icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
