.apk_file_container {
  padding: 0 0 1rem 0;
  overflow: auto;
}

.apk_file_table {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.apk_file_header {
  font-family: var(--fontfamily-sansserif);
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 0.5rem 0;
}

.apk_file_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  border-bottom: 1px solid grey;
  padding: 0.5rem 10px;
}

.apk_file_btn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-color: #eaedff;
}

.apk_file_icon {
  color: var(--color-red);
  font-size: var(--fontsize-medium);
  cursor: pointer;
}
