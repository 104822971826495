.host__call__history__container {
  padding:0 0 3rem 0;
}

.host__call__history__table {
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
}

.host__call__history__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px;
}

.host__call__history__data {
  text-align: center;
  font-family: var(--fontfamily-default);
  padding: 10px;
  border: none;
  border-bottom: 1px solid black;
}
