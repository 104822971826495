.alert__popup__container {
  gap: 10px;
}

.alert__popup__heading {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-large);
  font-weight: var(--fontweight-medium);
}

.alert__popup__content {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
}

.button {
  display: flex;
  justify-content: end;
  /* margin-top: 30px; */
  padding: 15px;
  gap: 30px;
  cursor: pointer;
  font-size: 14px;
}

.alert__submit__popup {
  width: 5rem;
  background-color: rgb(0, 132, 255);
  font-family: var(--fontfamily-sansserif);
  font-size: 20px;
  padding: 10px 0;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.alert__cancel__popup {
  width: 5rem;
  background-color: red;
  font-family: var(--fontfamily-sansserif);
  font-size: 20px;
  color: white;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
}

.alert__popup__dropdown {
  text-align: center;
}

.alert_popup_textfield {
  align-items: center;
}

.alert_popup_wrap {
  display: flex;
  flex-direction: column;

  gap: 20px;
  /* align-items: center; */
}
