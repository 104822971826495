.recharge__table__container {
  padding: 3rem 0;
}

.recharge__table {
  width: 100%;
  border-collapse: collapse;
}

.recharge__table__heading {
  background-color: var(--color-pink);
  padding: 10px 0;
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-regular);
  font-weight: var(--fontweight-medium);
}

.recharge__table__body {
  padding: 10px 0;
  text-align: center;
  font-family: var(--fontfamily-default);
  border-bottom: 1px solid grey;
}
