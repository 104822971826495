@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,400;1,500&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap"); */



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:"Rockwell", sans-serif ;
}
th {
  background-color: #eaedff !important;
  color: #8f95b2  !important;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  position:sticky;
  top:0;
  font-weight: 700 !important;
  font-size:14px !important


}
td {
  max-width: 150px;
overflow: hidden;
text-overflow: ellipsis;
white-space:no-wrap;
  width: 150px;
  min-width: 150px;
  height: 70px;
  border: none !important;
  color: #8f95b2;
  font-weight: 400;
  font-size:12px !important
}
tbody tr:nth-child(even) {
  background-color: #eaedff;
  color: #8f95b2;
}
tbody tr:nth-child(odd) {
  background-color: #ffff;
  color: #8f95b2;
}
th{
  position: sticky;
}

th {
  text-transform: uppercase;
}

td {
  text-align: center;
  vertical-align: middle;
}

:root {
  --fontfamily-sansserif: "Rockwell", sans-serif;
  --fontfamily-default:'Rockwell' , sans-serif;
  /* --fontfamily-serif: "Lora", serif; */
  /* --fontfamily-cursive: "Dancing Script", cursive; */

  --fontsize-xxlarge: 50px;
  --fontsize-xlarge: 30px;
  --fontsize-large: 25px;
  --fontsize-medium: 20px;
  --fontsize-regular: 18px;
  --fontsize-small: 16px;
  --fontsize-xsmall: 14px;
  --fontsize-xxsmall:12px;

  --fontweight-black: 700;
  --fontweight-bold: 600;
  --fontweight-medium: 500;
  --fontweight-regular: 400;
  --fontweight-light: 300;

  --color-pink: #6c5dd3;
  --color-grey: #a9a9a9;
  --color-lightgrey: #ebebeb;
  --color-white: #fff;
  --color-black: #000;
  --color-red: #ec1010;
  --color-blue: #0077ff;

  --color-lightpink: #ffdff3;

  --button-height: 50px;
  --button-radius: 24px;
  --button-circle: 100%;
}

.error_alert{
  font-size:12px;
  color:red;
  text-align: left;
}
.form__data {
  width: 20rem;
}
body{
  font-family: 'Rockwell',;
}
.App{
  overflow: hidden;
}
table{
  table-layout: fixed;
  width:100% !important;
  overflow: auto;
}