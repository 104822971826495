.chart_box input {
  height: 40px;
  width: 150px;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

.dashboard_card_btn_row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.dashboard_graph_start_date {
  display: flex;
  color: #64748b;
  flex-direction: column;
  font-family: var(--fontfamily-sansserif);
}

.dashboard__date {
  color: #64748b;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
}

.dashboard_graph_btns {
  height: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 15px;
}
