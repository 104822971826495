.host__request__container {
  padding:0 0  1rem 0;
  /* overflow: auto; */
}

.host__request__table {
  min-width: max-content;
  width: 100%;
  border-collapse: collapse;
}

.host__request__data {
  padding: 10px 5px;
  border: none;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-default);
  font-size: var(--fontsize-small);
  text-align: center;
}

.host__request__action__icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.host__request__eye__icon {
  font-size: 20px;
  cursor: pointer;
}

.host__request__accept__icon {
  font-size: 25px;
  color: rgb(2, 172, 2);
  cursor: pointer;
}

.host__request__reject__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.host__request__header {
  background-color: #f20094;
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  color: white;
  padding: 10px;
}

.host__no__data__found__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}

.host__no_data_found {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  font-size: var(--fontsize-regular);
  color: var(--color-grey);
  margin-top: -20px;
}
