.button__style {
  width: 8rem;
  height: 2.5rem;
  min-height:30px;
  padding: 0 15px;
  color: var(--color-black);
  /* background-color: var(--color-pink); */
  background-color: #6c5dd3;
  border-radius: 5px;
  border: none;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform:uppercase
}

.button__text {
  color: var(--color-white);
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-default);
}
