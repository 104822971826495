.premium__coin__heading {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid black;
}

/* .premium__coin__container {
  padding: 0.5rem;
} */

.premium__coin {
  padding: 1rem;
}
