.dropdown__input {
  width: 100%;
  height: 1.5rem;
  border: none;
  border-bottom: 2px solid #707070;
  outline: none;
  font-family: var(--fontfamily-default);
  background-color: transparent;
}

.dropdown__content {
  font-family: var(--fontfamily-default);
}
