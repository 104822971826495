.user__sticker__container {
  padding: 3rem 0;
}

.user__sticker__table {
  width: 100%;
  border-collapse: collapse;
}

.user__sticker__header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  padding: 10px;
}

.user__sticker__data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  padding: 10px;
  border: none;
  border-bottom: 1px solid grey;
}

.user__sticker__eye__icon {
  font-size: 20px;
  cursor: pointer;
}
