.warn__user__button {
  /* margin: auto; */
}

.warn__user__heading {
  text-align: center;
  font-size: 20px;
  margin:0px;
  font-weight: medium;
}

.user__management__warn__user {
  width: 50%;
  height: 50%;
  max-width:500px;
  max-height:266px;
  min-height:240px;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: hsla(0, 0%, 0%, 0.24) 0px 3px 8px;
}
