.gift__table__container {
  max-width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.gift__container {
  padding: 0 0 1rem 0;
}

.gift__table__heading {
  color: var(--color-white);
  background-color: var(--color-pink);
  font-weight: var(--fontweight-medium);
  font-family: var(--fontfamily-sansserif);
  padding: 10px 0;
}

.gift__table__body {
  text-align: center;
  padding: 5px 5px;
  font-family: var(--fontfamily-default);
  font-size: var(--fontsize--small);
  border: none;
  border-bottom: 1px solid grey;
}

.gift__table__edit__icon {
  font-size: 25px;
  color: rgb(0, 140, 255);
  cursor: pointer;
}

.gift__table__delete__icon {
  font-size: 25px;
  color: red;
  cursor: pointer;
}

.add__gift {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding:20px;
  background-color: #EAEDFF;
}

.gift__table__icon {
  cursor: pointer;
}

.gift__table__eye__icon {
  font-size: 18px;
  cursor: pointer;
}
