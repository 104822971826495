.helpline__header {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-large);
  font-weight: var(--fontweight-bold);
  border-bottom: 1px solid black;
  padding: 0.5rem;
}

.helpline__content {
  margin: 1rem;
}
