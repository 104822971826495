.top__growing__dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-bottom: 10px; */
}
.top_talent_table_conainer {
  /* width: 100%;
  height: 100%;
  overflow-y: auto; */
}
