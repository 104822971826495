.user_moment_container {
  padding:0 0 1rem 0;
  height:100% !important;
  width: 100% !important;
    overflow: auto !important;
}

.user_moment_table {
  min-width: max-content;
  width: 100%;
  /* min-height:300px; */
  border-collapse: collapse;
}

.user_moment_header {
  font-family: var(--fontfamily-sansserif);
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 0.5rem;
}

.user_moment_data {
  text-align: center;
  border-bottom: 1px solid grey;
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
}
